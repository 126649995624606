@keyframes fadeOut {
	100% {
		opacity: 0;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes rollingBackground {
	100% {
		background-position: 55px 0;
	}
}

@keyframes pulsing {
	0% {
		transform: scaleX(0.2);
	}

	50% {
		transform: scaleX(0.9);
	}

	100% {
		transform: scaleX(0.2);
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes pendingRotation {
	0% {
		transform: rotate(0);
	}

	33%,
	66% {
		transform: rotate(180deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes flickering {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.2;
	}

	100% {
		opacity: 1;
	}
}
