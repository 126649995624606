// regular style toast
/* position */
.toast-center-center {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.toast-top-center {
	top: 0;
	right: 0;
	width: 100%;
}

.toast-bottom-center {
	bottom: 0;
	right: 0;
	width: 100%;
}

.toast-top-full-width {
	top: 0;
	right: 0;
	width: 100%;
}

.toast-bottom-full-width {
	bottom: 0;
	right: 0;
	width: 100%;
}

.toast-top-left {
	top: calc(#{$toolbar-height} + 1rem);
	left: 1rem;
}

.toast-top-right {
	top: calc(#{$toolbar-height} + 1rem);
	right: 1rem; // 1 plus rem for scrollbar
}

.toast-bottom-right {
	right: 12px;
	bottom: 12px;
}

.toast-bottom-left {
	bottom: 12px;
	left: 12px;
}

.toast-container {
	pointer-events: none;
	position: fixed;
	z-index: 999999;

	&.toast-top-center bp-toast,
	&.toast-bottom-center bp-toast {
		width: 300px;
		margin-left: auto;
		margin-right: auto;
	}

	&.toast-top-full-width bp-toast,
	&.toast-bottom-full-width bp-toast {
		width: 96%;
		margin-left: auto;
		margin-right: auto;
	}
}
