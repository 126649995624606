bp-svg-icon,
.material-icons,
.material-icons-round {
	@each $type, $size in $icons-sizes {
		.mat-icon-button.bp-icon-button-#{$type} & {
			@include size($size);
			@include fontsize($size);
		}
	}
}

@each $type, $size in $icons-sizes {
	.bp-icon-#{$type} {
		&,
		&.material-icons,
		&.material-icons-round {
			@include size($size !important);
			@include fontsize($size !important);
		}
	}
}

.mat-icon {
	transition: color $transition-props;
}

.mat-icon-button {
	display: inline-flex !important;
	align-items: center;
	justify-content: center;

	.mat-icon {
		display: block;
	}

	&.bp-icon-button-xxs {
		@include iconSize(30px);
	}

	&.bp-icon-button-xs {
		@include iconSize(30px);
	}

	&.bp-icon-button-sm {
		@include iconSize($icon-size-sm + 8px);
	}

	&:not(.bp-icon-button-xxs):not(.bp-icon-button-xs):not(.bp-icon-button-sm):not(.bp-icon-button-big):not(.bp-icon-button-lg):not(.bp-icon-button-xl):not(.bp-icon-button-xxl) {
		@include iconSize($icon-size-md + 16px);
	}

	&.bp-icon-button-big {
		@include iconSize($icon-size-big + 9px);
	}

	&.bp-icon-button-lg {
		@include iconSize($icon-size-lg + 5px);
	}

	&.bp-icon-button-xl {
		@include size($icon-size-xl);
	}

	&.bp-icon-button-xxl {
		@include size($icon-size-xxl);
	}
}
