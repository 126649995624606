$pendingStubAnimation: pendingStubAnimation 1.7s linear infinite;
$fadeOut: fadeOut $animation-duration ease forwards;
$bg-width: 250px;
$bg-color: fade-out($color-lightgray, 0.7);

.pending-stub:before {
	content: 'stub';
	color: transparent;
	display: inline-block;
	border-radius: 5px;
	width: 100%;
	$gradient: -45deg, $bg-color 0%, $bg-color 15%, lighten($bg-color, 18%) 50%, lighten($bg-color, 18%) 60%,
		$bg-color 95%, $bg-color 100%;
	background-image: linear-gradient($gradient);
	background-size: $bg-width 55px;
	animation: $pendingStubAnimation;
}

.pending-stubs,
.mat-card-content-table {
	&.loading {
		.mat-cell {
			&:not(:first-of-type):not(:last-of-type),
			&:first-of-type {
				padding-right: 0.5rem;
			}
		}

		output,
		.mat-header-cell,
		.mat-h2,
		.mat-cell {
			@extend .pending-stub;
		}

		.mat-header-cell > span {
			display: none;
		}

		.mat-h2:before {
			line-height: $title-font-size;
		}
	}

	&.hide-pending-stubs {
		output,
		.mat-header-cell,
		.mat-h2,
		.mat-cell {
			position: relative;
			@extend .pending-stub;

			&:before {
				width: auto;
				left: 0;
				right: 0;
				position: absolute;
				animation: $pendingStubAnimation, $fadeOut;
				z-index: 1;
			}
		}

		.mat-cell {
			&:before {
				right: 1rem;
			}

			&:first-of-type:before {
				left: 1rem;
			}
		}
	}
}

@keyframes pendingStubAnimation {
	100% {
		background-position: $bg-width 0;
	}
}
