@use 'sass:math';
@use '@angular/material' as mat;

.alert {
	@include elevation(1);
	overflow: hidden;
	padding: 1rem 1.25rem;
	padding-right: 1.5rem;
	margin-bottom: 2rem;
	border-radius: math.div($card-border-radius, 2);

	.btn-icon-close {
		right: 6px;
		top: 6px;
		color: inherit;
		opacity: 0.5;
	}

	@each $type, $scheme in $bp-colors-palette {
		&.alert-#{$type} {
			$color: darken(mat.get-color-from-palette($scheme), 40%);
			$border-color: transparentize($color, 0.85);
			background: mat.get-color-from-palette($scheme, 50);
			color: $color;

			> :first-child {
				margin-top: 0;
			}

			> :last-child {
				margin-bottom: 0;
			}

			::ng-deep {
				hr {
					background: $border-color;
				}
			}

			&.alert-with-border {
				border: 1px solid $border-color;
			}
		}
	}

	&.alert-warning {
		background: #ffc63d;
	}
}
