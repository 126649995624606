body:before {
	position: fixed;
	left: -999999999px;
	top: -999999999px;
	visibility: hidden;

	$serializedBreakpoints: '';

	@each $type, $width in $breakpoints {
		$serializedBreakpoints: $serializedBreakpoints + $type + ':' + $width + '|';
	}

	content: '#{$serializedBreakpoints}';
}
