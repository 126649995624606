@use '@angular/material' as mat;

.cdk-overlay-container {
	.cdk-overlay-dark-backdrop {
		background: $cdk-overlay-dark-backdrop-background;
		transition: $transition-props;
		transition-property: backdrop-filter, opacity;

		&.cdk-overlay-backdrop-showing {
			backdrop-filter: blur($cdk-overlay-dark-backdrop-filter-blur);
		}
	}

	.mat-dialog-container {
		border-radius: $card-border-radius;
		padding: $dialog-padding;

		h1 {
			margin-top: 0;
			margin-bottom: $dialog-header-margin;
			color: $color-dark;
		}

		.mat-progress-bar {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 50;
		}
	}

	.mat-dialog-container {
		@include elevation($elevation-modal);
	}

	.mat-drawer.mat-sidenav {
		@include elevation($elevation-sidenav);
	}

	.mat-select-panel,
	.mat-autocomplete-panel,
	.mat-datepicker-content,
	.mat-menu-panel {
		@include elevation($elevation-panel);
	}
}

.mat-dialog-container,
.mat-sidenav,
.alert {
	position: relative;

	.btn-icon-close {
		position: absolute;
		color: $color-mediumgray;
		z-index: 2;

		svg {
			$size: 20px;
			width: $size;
			height: $size;
		}
	}
}

.mat-sidenav {
	z-index: $z-index-sidenav !important;
}

.cdk-global-scrollblock {
	overflow: hidden !important;
}

.mat-select-panel,
.mat-selection-list,
.mat-autocomplete-panel {
	> .cdk-virtual-scroll-viewport {
		height: 240px; // the item height is 48px, we want the list to be 5 items long

		.cdk-virtual-scroll-content-wrapper {
			width: 100%;
		}
	}
}

.mat-select-panel,
.mat-autocomplete-panel {
	.mat-option {
		overflow: unset;
	}
}

.mat-autocomplete-panel {
	.mat-option {
		&.selected {
			background: $color-primary !important;
			color: white !important;
		}

		.sub-option {
			margin-left: 1rem;
			font-size: $font-size-lg - 1px;
		}
	}
}

.mat-button,
.mat-flat-button,
.mat-icon-button,
.mat-stroked-button {
	transition: $transition-props;
	transition-property: border-color, background, color, box-shadow;
}

.mat-stroked-button[disabled] {
	border-color: $color-border;
}

.mat-chip.mat-standard-chip {
	min-height: 28px;
	font-weight: 400;
}

.headless-mat-tab-group {
	.mat-tab-header {
		display: none;
	}

	.mat-tab-body-content {
		overflow: hidden;
	}
}

.lean-mat-form-field .mat-form-field-wrapper {
	padding-bottom: unset;

	.mat-form-field-flex {
		padding-top: unset;

		.mat-form-field-infix {
			border-top: none;
		}
	}

	.mat-form-field-underline {
		bottom: unset;
	}
}
