.mat-button {
	&.bp-button {
		font-size: $button-font-size;
		font-weight: $button-font-weight;
		line-height: $button-line-height;
		height: 45px;

		&.md {
			font-size: $font-size-lg;
			line-height: $font-size-lg;
			height: 40px;
		}
	}

	&.hr-button {
		font-size: inherit;
		font-weight: inherit;

		.mat-button-wrapper {
			display: flex;
			align-items: center;

			.mat-icon {
				margin-left: 0.5rem;
			}
		}
	}
}

bp-pending-btn,
bp-pending {
	.mat-progress-spinner {
		$size: 30px;
		margin-left: -$size;

		&,
		svg {
			width: $size !important;
			height: $size !important;
		}
	}
}
