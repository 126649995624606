.psps-autocomplete-panel mat-option {
	$height: 50px;
	line-height: $height;
	height: $height;

	.mat-option-text {
		bp-psp-thumbnail {
			display: inline-block;
			vertical-align: middle;

			.mat-icon {
				margin-right: 0;
			}
		}

		span {
			margin-left: 0.5rem;
			vertical-align: middle;
		}
	}
}
