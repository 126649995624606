@use 'sass:math';
@use '@angular/material' as mat;

.bp-toolbar {
	mat-toolbar {
		@include elevation(10, $elevation-color, 0.7);
		height: $toolbar-height;
		padding: 0 $layout-padding;
		position: fixed;
		top: 0;
		z-index: 1;
		color: white;
		font-size: $font-size-md;
		transition: background $transition-props;

		.btn-menu {
			margin-left: -5px;
		}

		.btn-logo {
			margin: 0 math.div(1rem, 2);
			height: 100%;
			display: flex;
			align-items: center;
		}

		.btn-icon bp-svg-icon {
			width: $toolbar-icon-size;
			height: $toolbar-icon-size;
			display: inline-flex;
		}

		.left {
			flex: 1;
		}

		.left,
		.right {
			display: flex;
			flex-direction: row;
			align-items: center;
			white-space: nowrap;
		}

		.mat-button {
			font-weight: $font-weight-normal;
		}

		button.user {
			img {
				width: $avatar-size;
				height: $avatar-size;
				border-radius: $avatar-size;
				margin-right: 0.5rem;
			}
		}
	}
}

.mat-menu-panel.user-menu {
	min-width: 175px;
}
