/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1) !important;
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
	transition: transform 300ms cubic-bezier(0, 0, 0.2, 1) !important;
}

.cdk-drag {
	user-select: none;
	-webkit-user-drag: none;

	&:hover .drag-handle {
		opacity: 1;
	}

	&.cdk-drag-preview {
		background: white;

		.drag-handle {
			opacity: 1;
		}
	}

	.drag-handle {
		height: 100%;
		position: absolute;
		top: 0;
		left: 2px;
		display: flex;
		align-items: center;
		width: $right-drawer-card-padding-x;
		justify-content: center;
		color: $color-mediumgray;
		cursor: grab;
		opacity: 0;
		transition: opacity $transition-props;
	}
}

.cdk-drag-preview {
	@include elevation-11-y-zero();

	.mat-ripple-element {
		visibility: hidden;
	}
}
