input {
	&:-webkit-autofill {
		$long-time: 100000s;
		// a trick to stop browsers autofill background coloration;
		transition: background-color $long-time, color $long-time;

		&:disabled {
			-webkit-text-fill-color: $color-dark-disabled-text;
		}
	}

	&:not(.cdk-text-field-autofilled) {
		transition-delay: 0;
	}
}

fieldset {
	margin: 0;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}
}

textarea.mat-input-element {
	line-height: $body-line-height;
}
