.status-bar-top,
.status-bar-left {
	position: relative;
	overflow: unset !important;
}

.status-bar-inner-top,
.status-bar-inner-left {
	position: relative;
	overflow: hidden !important;
}

.status-bar-top {
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
	border-top-width: $mat-card-status-border-width;
	border-top-style: solid;
}

.status-bar-left {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
	border-left-width: $mat-card-status-border-width;
	border-left-style: solid;
}
